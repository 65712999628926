const themeGen = theme => ({  // eslint-disable-line
  hideTotalEntrySummary: true,
  showStockStatus: true,
  cartPotentialBuyBg: '#ebebeb',
  cartPotentialBuyComponentXMargin: 'mx-2',
  updateShippingString: true,
  isHPLeagalSpecialCase: true,
});

export default themeGen;
