const themeGen = theme => ({ // eslint-disable-line
  backgroundColor: '#e8008c',
  textColor: 'white',
  textWeight: 'bold',
  deckForProductsList: true,
  textAlignment: 'left',
  headerFontFamily: 'ICGravostyleBold',
  roundedHeaderSection: false,
});

export default themeGen;
