const b2bLogo = '/nordic/common/inkclub_logo_b2b.svg';
const b2cLogo = '/nordic/common/inkclub_logo_b2c.svg';

const themeGen = theme => { // eslint-disable-line
  const res = {
    siteLogo: {
      imagesRootPath: '/images/sitelogo',
      paths: {
        b2c: {
          se: {
            sv: b2cLogo,
          },
          no: {
            no: b2cLogo,
          },
          dk: {
            da: b2cLogo,
          },
          fi: {
            sv_FI: b2cLogo,
            fi: b2cLogo,
          },
          // default: '/non-nordic/inkclub_logo.jpg',
          default: b2cLogo,
        },
        b2b: {
          se: {
            sv: b2bLogo,
          },
          no: {
            no: b2bLogo,
          },
          dk: {
            da: b2bLogo,
          },
          fi: {
            sv_FI: b2bLogo,
            fi: b2bLogo,
          },
          // default: '/non-nordic/inkclub_logo.jpg',
          default: b2bLogo,
        },
      },
    },
  };
  return res;
};

export default themeGen;
