// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
/* eslint-disable */
import Bubble from '~/packages/spa-ec/src/displayComponents/Bubble/Bubble.theme-inkclub.js';
import CartMod from '~/packages/spa-ec/src/components/Cart/CartMod.theme-inkclub.js';
import Cheapest from '~/packages/spa-ec/src/displayComponents/PrisjaktCheapest/Cheapest.theme-inkclub.js';
import ComponentsContainer from '~/packages/spa-ec/src/displayComponents/Cms/ComponentsContainer.theme-inkclub.js';
import ContactUsPage from '~/packages/spa-ec/src/routes/ContactUsPage/ContactUsPage.theme-inkclub.js';
import GDPRPopupMod from '~/packages/spa-ec/src/components/GDPRPopup/GDPRPopupMod.theme-inkclub.js';
import Images from '~/packages/spa-ec/src/components/ProductDetails/Images/themes/Images.theme-inkclub.js';
import MyPages from '~/packages/spa-ec/src/components/MyPages/MyPages.theme-inkclub.js';
import OrderConfirmation from '~/packages/spa-ec/src/components/OrderConfirmation/OrderConfirmation.theme-inkclub.js';
import OrderHistory from '~/packages/spa-ec/src/components/OrderHistory/OrderHistory.theme-inkclub.js';
import PersonalizedProductComponentMod from '~/packages/spa-ec/src/displayComponents/Cms/PersonalizedProductComponentMod.theme-inkclub.js';
import ProductGrid from '~/packages/spa-ec/src/displayComponents/ProductGrid/ProductGrid.theme-inkclub.js';
import ProductPromotionMod from '~/packages/spa-ec/src/components/ProductPromotion/ProductPromotionMod.theme-inkclub.js';
import ProductRecommendationMod from '~/packages/spa-ec/src/components/ProductRecommendation/ProductRecommendationMod.theme-inkclub.js';
import RestoreCartPanel from '~/packages/spa-ec/src/components/RestoreCart/RestoreCartPanel.theme-inkclub.js';
import Search from '~/packages/spa-ec/src/components/Search/Search.theme-inkclub.js';
import SearchList from '~/packages/spa-ec/src/components/Search/SearchList.theme-inkclub.js';
import SiteLogo from '~/packages/spa-ec/src/components/Header/HeaderSiteLogo/SiteLogo.theme-inkclub.js';
import SubscriptionBox from '~/packages/spa-ec/src/components/ProductDetails/SubscriptionBox/themes/SubscriptionBox.theme-inkclub.js';
import USPItem from '~/packages/spa-ec/src/displayComponents/Cms/USPItem.theme-inkclub.js';
import USPWrapper from '~/packages/spa-ec/src/displayComponents/Cms/USPWrapper.theme-inkclub.js';

const overrides = {
  Bubble,
  CartMod,
  Cheapest,
  ComponentsContainer,
  ContactUsPage,
  GDPRPopupMod,
  Images,
  MyPages,
  OrderConfirmation,
  OrderHistory,
  PersonalizedProductComponentMod,
  ProductGrid,
  ProductPromotionMod,
  ProductRecommendationMod,
  RestoreCartPanel,
  Search,
  SearchList,
  SiteLogo,
  SubscriptionBox,
  USPItem,
  USPWrapper,
};
export default overrides;

// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
