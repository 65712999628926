import { NAME as CardName } from '@spa-ec/components/Card/Card.theme';
import { NAME as PromotionsName } from '@spa-ec/components/Header/HeaderPromotions/Promotions.theme';
import { NAME as OfferBannerName } from '@spa-ec/components/PersonalizedCockpit/OfferBanner/OfferBanner.theme';
import { NAME as ReplacementPanelName, } from '@spa-ec/components/ProductDetails/ReplacementPanel/ReplacementPanel.theme';
import { NAME as MobileDropDownName, } from '@spa-ec/components/Header/HeaderMobileDropDown/MobileDropDown.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as CartSummaryName } from '@spa-ec/components/QliroCheckout/CartSummary.theme';
import { NAME as QliroCheckoutName } from '@spa-ec/components/QliroCheckout/QliroCheckout.theme';
import { NAME as ProductDetailsName } from '@spa-ec/components/ProductDetails/ProductDetails.theme';
import { NAME as OneBuyPanelName } from '@spa-ec/components/ProductDetails/OneBuyPanel/OneBuyPanel.theme';
import { NAME as CategoryListingName } from '@spa-ec/components/CategoryListing/CategoryListing.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as FooterName } from '@spa-ec/components/Footer/Footer.theme';
import { NAME as PasswordRequestName } from '@spa-ec/components/PasswordRequest/PasswordRequest.theme';
import { NAME as ListFinderName } from '@spa-ec/components/ListFinder/ListFinder.theme';
import { NAME as ShipmentAddressFormName, } from '@spa-ec/components/Checkout/AddressSection/ShipmentAddressForm.theme';
import { NAME as ProductRowName } from '@spa-ec/components/ProductTable/Rows/ProductRow.theme';
import theme from './theme.conf';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    sideContentPadding: 'px-4',
};
const cardTheme = {
    showEnKrBackground: true,
};
const promotionsTheme = {
    promotionImagePath: '/_ui/dist/theme-inkclub/images/icons/bullhorn_ebk_inkclub.svg',
};
const offerBannerTheme = {
    enabledOfferBanner: true,
};
const replacementPanelTheme = {
    emvWarrantyImagePath: '/images/icon-Garanti-small.png',
};
const mobileDropDownTheme = {
    contactUsUrl: '/contact_us',
};
const cartSummaryTheme = {
    headerFontSize: 'x1',
    summaryFontSize: 'm',
    summaryFontClass: 'text-m',
    borderInSummary: true,
};
const qliroCheckoutTheme = {
    primaryColor: theme.col.primary.fg,
    callToActionColor: theme.col.primary.fg,
    callToActionHoverColor: theme.col.primary.peer,
};
const productDetailsTheme = {
    productDetailsSeparator: '&emsp;',
    borderSpecificationTable: false,
    isHPLeagalSpecialCase: true,
    productDetailsBoldness: true,
};
const oneBuyPanelTheme = {
    iconHeight: '100%',
    innerDivJustification: ' justify-end',
    showCartIcon: false,
    oneKrSticker: {
        rootPath: 'images/icons/',
        sticker: {
            se: '1krSticker.png',
            at: '1krStickerFi.png',
            ch: '1krSticker_chf.png',
            nl: '1krSticker_pre_euro.png',
            no: '1krStickerDkNo.png',
            fr: '1krStickerFi.png',
            de: '1krStickerFi.png',
            dk: '1krStickerDkNo.png',
            be: '1krStickerFi.png',
            fi: '1krStickerFi.png',
            default: '1krSticker.png',
        },
    },
};
const categoryListingTheme = {
    infoTextFontSize: '14px',
    showCategoryBrandLogoList: true,
};
const pageTitleTheme = {
    noBorder: true,
    fg: theme.col.primary.fg,
    bg: 'white',
    showLogoutIcon: false,
    combineSections: true,
    bottomBorderColor: '#343434',
};
const cartTableTheme = {
    showProductDetails: true,
    showPerKronaInCartTable: false,
};
const footerTheme = {
    showNewsLetter: true,
    showSocialMediaLinks: true,
};
const shipmentAddressFormTheme = {
    showShippingAddressCellphone: true,
};
const productRowTheme = {
    showOneKronaSticker: true,
    productCodeColor: theme.col.secondary.fg,
};
const listFinderTheme = {
    isHPLeagalSpecialCase: true,
};
const passwordRequestTheme = {
    enablePasswordRequestByCustomerNumber: true,
};
export default {
    [ContentPageName]: contentPageTheme,
    [CardName]: cardTheme,
    [PromotionsName]: promotionsTheme,
    [OfferBannerName]: offerBannerTheme,
    [ReplacementPanelName]: replacementPanelTheme,
    [MobileDropDownName]: mobileDropDownTheme,
    [CartSummaryName]: cartSummaryTheme,
    [QliroCheckoutName]: qliroCheckoutTheme,
    [ProductDetailsName]: productDetailsTheme,
    [OneBuyPanelName]: oneBuyPanelTheme,
    [CategoryListingName]: categoryListingTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [FooterName]: footerTheme,
    [ShipmentAddressFormName]: shipmentAddressFormTheme,
    [ProductRowName]: productRowTheme,
    [ListFinderName]: listFinderTheme,
    [PasswordRequestName]: passwordRequestTheme,
};
